export const DateFormatEnum = {
  FORMAT: "yyyy-MM-dd",
  SPACE_dd_MMM_yyyy: "dd MMMM yyyy",
  SPACE_MMMM_yyyy: "MMMM yyyy",
  SPACE_MMMM_dd: "MMMM dd",
  HYPHEN_dd_MM_yyyy: "dd-MM-yyyy",
  HYPHEN_MM_ddd_yyyy: "MM-ddd-yyyy",
};

export const DateLocaleEnum = {
  LOCALE: "en",
};

export const TIMEZONES = Intl.supportedValuesOf("timeZone").map((timeZone) => {
  const offsetName = new Intl.DateTimeFormat(undefined, {
    timeZone: timeZone,
    timeZoneName: "longOffset",
  })
    .formatToParts(new Date())
    .find((part) => part.type === "timeZoneName")?.value;

  const timeZoneName = new Intl.DateTimeFormat(undefined, {
    timeZone: timeZone,
    timeZoneName: "long",
  })
    .formatToParts(new Date())
    .find((part) => part.type === "timeZoneName")?.value;

  const abbreviation = new Intl.DateTimeFormat(undefined, {
    timeZone: timeZone,
    timeZoneName: "shortGeneric",
  })
    .formatToParts(new Date())
    .find((part) => part.type === "timeZoneName")?.value;

  return { timeZone, offsetName, timeZoneName, abbreviation };
});
