import logoImg from "assets/imgs/logo.png";
import clsx from "clsx";
import "./Logo.css";
import { Link } from "react-router-dom";
import { RouteEnum } from "constants/RouteConstants";

/**
 *
 * @param {import("react").ComponentPropsWithoutRef<"img">} props
 */
function Logo(props) {
  return (
    <Link to={RouteEnum.HOME}>
      <img
        src={logoImg}
        alt="logo"
        {...props}
        className={clsx("Logo", props.className)}
      />
    </Link>
  );
}

export default Logo;
