export const RouteEnum = {
  HOME: "/",
  DASHBOARD: "/",
  LOGIN: "/login",
  SIGNUP: "/signup",
  SIGNUP_VERIFICATION_REQUEST: "/signup/verification-request",
  SIGNUP_VERIFICATION: "/signup/verification/:token",
  SIGNUP_CONGRATULATIONS: "/signup/congratulations",
  PASSWORD_RESET: "/password/reset",
  PASSWORD_RESET_REQUEST: "/password/reset-request",
  CONTENT_CLIENT_GETTING_STARTED: "/getting-started",
  HOW_IT_WORKS: "/how-it-works",
  // CLIENTS: "/clients",
  // CLIENTS_DASHBOARD: "/clients/:id",
  // CLIENTS_PROFILE: "/clients/profile",
  // CLIENTS_PROFILE_UPDATE: "/clients/profile/update",
  // CLIENTS_HIRES: "/clients/my-hires",
  // FREELANCERS: "/freelancers",
  // FREELANCERS_DASHBOARD: "/freelancers/:id",
  // FREELANCERS_PROFILE: "/freelancers/profile",
  // FREELANCERS_PROFILE_UPDATE: "/freelancers/profile/update",
  // FREELANCERS_PROFILE_UPDATE_AVOID: "/freelancers/profile/update/avoid",
  // FREELANCERS_PROFILE_UPDATE_TIPS: "/freelancers/profile/update/tips",
  // FREELANCERS_STATS: "/freelancers/stats",
  // FREELANCERS_PROJECTS: "/freelancers/:id/projects",
  // FREELANCERS_PROJECTS_DASHBOARD: "/freelancers/project-dashboard",
  // FREELANCERS_RECENTLY_VIEWED: "/freelancers/recently-viewed",
  // FREELANCERS_SAVED: "/freelancers/saved",
  PROFILE: "/profile",
  PROFILE_STATS: "/profile/stats",
  PROFILE_FREELANCERS: "/profile/freelancers",
  PROFILE_FREELANCERS_DASHBOARD: "/profile/freelancers/:id",
  PROFILE_FREELANCERS_SEARCH: "/profile/freelancers/search",
  PROFILE_FREELANCERS_RECENTLY_VIEWED: "/profile/freelancers/recently-viewed",
  PROFILE_FREELANCERS_SAVED: "/profile/freelancers/saved",
  PROFILE_PROJECTS_SAVED: "/profile/projects/saved",
  PROFILE_CLIENTS: "/profile/clients",
  PROFILE_CLIENTS_DASHBOARD: "/profile/clients/:id",
  PROFILE_DASHBOARD: "/profile/:id",
  PROFILE_CREATE: "/profile/create",
  PROFILE_UPDATE: "/profile/update",
  PROFILE_UPDATE_AVOID: "/profile/update/avoid",
  PROFILE_UPDATE_TIPS: "/profile/update/tips",
  PROFILE_PORTFOLIO: "/profile/portfolio",
  PROFILE_PORTFOLIO_ADD: "/profile/portfolio/add",
  PROFILE_PORTFOLIO_EDIT: "profile/portfolio/:id/edit",
  SETTINGS: "/settings",
  SETTINGS_PAYMENTS: "/settings/payments",
  SETTINGS_BILLING: "/settings/payments/billing",
  SETTINGS_DISBURSEMENT: "/settings/payments/disbursement",
  SETTINGS_MEMBERSHIP: "/settings/membership",
  SETTINGS_CONTACT: "/settings/contact",
  SETTINGS_TAX: "/settings/tax",
  SETTINGS_TAX_HISTORY: "/settings/tax/history",
  SETTINGS_PROFILE: "/settings/profile",
  SETTINGS_TEAM: "/settings/team",
  SETTINGS_SERVICES: "/settings/services",
  SETTINGS_SECURITY: "/settings/security",
  SETTINGS_NOTIFICATION: "/settings/notification",
  PROJECTS: "/projects",
  PROJECTS_OVERVIEW: "/projects/overview",
  PROJECTS_SEARCH: "/projects/search",
  PROJECTS_FREELANCER_DASHBOARD: "/projects/my-dashboard",
  PROJECTS_DASHBOARD: "/projects/:id",
  PROJECTS_CREATE: "/projects/create",
  PROJECTS_EDIT: "/projects/:id/edit",
  JOBS: "/jobs",
  JOBS_OVERVIEW_CLIENT: "/jobs/overview/client",
  JOBS_OVERVIEW_FREELANCER_EARN: "/jobs/overview/freelancer/earn",
  JOBS_OVERVIEW_FREELANCER_PROMOTE: "/jobs/overview/freelancer/promote",
  JOBS_OVERVIEW_CATEGORIES: "/jobs/overview/categories",
  JOBS_OVERVIEW_CATEGORIES_DETAILS: "/jobs/overview/categories/:id",
  JOBS_DETAILS: "/jobs/:id",
  JOBS_CREATE: "/jobs/create",
  JOBS_EDIT: "/jobs/:id/edit",
  JOBS_REVIEW: "/jobs/:id/review",
  JOBS_REVIEW_TAB: "/jobs/:id/review/:tab",
  JOBS_SEARCH: "/jobs/search",
  JOBS_SEARCH_TAB: "/jobs/search/:tab",
  JOBS_SAVED: "/jobs/search/saved",
  JOBS_DASHBOARD: "/jobs/dashboard",
  JOBS_DASHBOARD_TAB: "/jobs/dashboard/:tab",
  JOBS_CLIENT_HIRES: "/jobs/my-hires",
  PROPOSALS: "/proposals",
  PROPOSALS_DETAILS: "/proposals/:id",
  PROPOSALS_TAB: "/proposals/tab/:tab",
  PROPOSALS_SEND: "/proposals/send",
  PROPOSALS_EDIT: "/proposals/:id/change-terms",
  CONTRACTS: "/contracts",
  CONTRACTS_WORKROOM: "/contracts/:id",
  CONTRACTS_WORKROOM_TAB: "/contracts/:id/:tab",
  CONTRACTS_ACTIVE: "/contracts/active",
  CONTRACTS_ACTIVE_TAB: "/contracts/active/:tab",
  CONTRACTS_SEND: "/contracts/send",
  CONTRACTS_EDIT: "/contracts/:id/edit",
  // JOBS_FREELANCER_DASHBOARD: "/jobs/my-dashboard",
  REPORTS: "/reports",
  REPORTS_OVERVIEW: "/reports/overview",
  REPORTS_SUMMARY: "/reports/summary",
  REPORTS_BILLINGS_AND_EARNINGS: "/reports/earnings",
  REPORTS_PAYMENTS: "/reports/transactions",
  REPORTS_BUDGETS: "/reports/budgets",
  MESSAGES: "/messages",
  MESSAGES_ROOMS: "/messages/rooms",
  PAYMENTS: "/payments",
  PAYMENTS_PAY: "/payments/pay/:authCode",
  PAYMENTS_PAY_RETURN: "/payments/pay/:authCode/:type",
  PRIVACY_POLICY: "/privacy-policy",
  TERM_OF_SERVICE: "/term-of-service",
};
