import { SoftwrkApi } from "configs/StoreQueryConfig";
import { StoreQueryTagEnum } from "constants/StoreConstants";
import { provideTags } from "utils/QueryUtils";

const CITY_BASE_URL = "/api/v1/cities";

export const CityApi = SoftwrkApi.injectEndpoints({
  endpoints: (builder) => ({
    getCities: builder.query({
      query: (config) => ({
        url: `${CITY_BASE_URL}`,
        ...config,
      }),
      providesTags: (data, error) =>
        provideTags(StoreQueryTagEnum.CITY, data?.data, error),
    }),
    getCity: builder.query({
      query: ({ path, ...config }) => ({
        url: `${CITY_BASE_URL}/${path?.id}`,
        ...config,
      }),
      providesTags: (data, error) =>
        provideTags(StoreQueryTagEnum.CITY, [data?.data], error),
    }),
  }),
});

export default CityApi;
