import { Autocomplete } from "@mui/material";
import CityApi from "apis/CityApi";
import useDebouncedState from "hooks/useDebouncedState";
import { forwardRef, useState } from "react";
import { normalizeArray } from "utils/ObjectUtils";

const CityAutocomplete = forwardRef(
  /**
   *
   * @param {CityAutocompleteProps} props
   * @param {*} ref
   * @returns
   */
  function CityAutocomplete(props, ref) {
    const {
      mapOptions,
      inputValueQueryParams,
      valueQueryParams,
      ...restProps
    } = props;
    const [q, setQ] = useState("");

    const inputValue = restProps.inputValue || q;

    const [debouncedInputValue] = useDebouncedState(inputValue, {
      wait: 200,
      enableReInitialize: true,
    });

    const citiesQueryResult = CityApi.useGetCitiesQuery(
      { params: { _search: debouncedInputValue, ...inputValueQueryParams } }
      // { skip: !inputValue }
    );

    const valueCitiesQueryResult = CityApi.useGetCitiesQuery(
      {
        params: {
          ...((idsOrnames) => ({
            $or: [{ id: idsOrnames }, { name: idsOrnames }],
          }))(
            restProps.value
              ? JSON.stringify({
                  $in: Array.isArray(restProps.value)
                    ? restProps.value
                        ?.map((item) =>
                          typeof item === "object" ? [item.id, item.name] : item
                        )
                        ?.flat()
                    : typeof restProps.value === "object"
                    ? [restProps.value?.id, restProps.value?.name]
                    : [restProps.value],
                })
              : undefined
          ),
          country_code: inputValueQueryParams?.country_code || undefined,
          state_code: inputValueQueryParams?.state_code || undefined,
        },
      },
      {
        skip: !(
          (Array.isArray(restProps.value)
            ? restProps.value?.length
            : restProps.value) && inputValueQueryParams?.state_code
        ),
      }
    );

    const allOptions = [
      ...(citiesQueryResult.data?.data ||
        citiesQueryResult.currentData?.data ||
        []),
      ...(valueCitiesQueryResult.data?.data ||
        valueCitiesQueryResult.currentData?.data ||
        []),
    ];
    const normalizedOptions = normalizeArray(allOptions);
    const normalizedNameOptions = normalizeArray(allOptions, {
      getKey: ({ name }) => name,
    });
    const options = Object.values(normalizedOptions);

    function getName(id) {
      return (
        id?.name ||
        normalizedOptions?.[id?.id || id]?.name ||
        normalizedNameOptions?.[id?.name || id]?.name ||
        id ||
        ""
      );
    }

    const onInputChange =
      restProps.onInputChange || ((_, value) => setQ(value));

    return (
      <Autocomplete
        key={getName(restProps?.value)}
        ref={ref}
        loading={citiesQueryResult.isFetching}
        freeSolo
        // options={mapOptions(options)}
        options={options}
        getOptionLabel={(option) => {
          return getName(option);
        }}
        isOptionEqualToValue={(option, value) => {
          return (
            option === value || option?.id === value?.id || option?.id === value
          );
        }}
        {...restProps}
        inputValue={inputValue}
        onInputChange={onInputChange}
      />
    );
  }
);

export default CityAutocomplete;

CityAutocomplete.defaultProps = {
  mapOptions: (options) => options,
};

/**
 * @typedef {{
 * inputValueQueryParams: any;
 * valueQueryParams: any;
 * mapOptions: (options: any[]) => any[]
 * } & import("@mui/material").AutocompleteProps} CityAutocompleteProps
 */
