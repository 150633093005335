import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { logoutAction } from "configs/StoreActionConfig";
import { useNavigate } from "react-router-dom";
import { RouteEnum } from "constants/RouteConstants";
import gis from "configs/GoogleIdentityService";

function useLogout(props) {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const logout = useCallback(
    function logout() {
      dispatch(logoutAction());
      gis.disableAutoSelect();
      return navigate(RouteEnum.HOME);
    },
    [dispatch, navigate]
  );

  return { logout };
}

export default useLogout;
