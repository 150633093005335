import store from "./StoreConfig";
import { logoutAction } from "./StoreActionConfig";
import { SoftworkHttp } from "./HttpConfig";

SoftworkHttp.interceptors.request.use((config) => {
  const { access_token } = store.getState().global.authUser || {};
  config.headers["App-ID"] = process.env.REACT_APP_SOFTWORK_APP_ID;

  if (access_token) {
    config.headers.Authorization = `Bearer ${access_token}`;
  }

  return config;
});

SoftworkHttp.interceptors.response.use(undefined, (error) => {
  const authUser = store.getState().global.authUser;
  if (error?.response?.status === 401 && authUser) {
    store.dispatch(logoutAction());
  }
  return Promise.reject(error);
});
