import { RouteEnum } from "constants/RouteConstants";
import useAuthUser from "hooks/useAuthUser";
import { Navigate, useLocation } from "react-router-dom";

function RedirectProtected() {
  const location = useLocation();

  const authUser = useAuthUser();

  return (
    <Navigate
      to={
        location.state?.previousLocation ??
        ((authUser?.progress || 0) < 50
          ? RouteEnum.HOW_IT_WORKS
          : RouteEnum.HOME)
      }
      state={location.state?.previousLocation?.state}
      replace
    />
  );
}

export default RedirectProtected;
