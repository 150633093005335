import gis from "configs/GoogleIdentityService";
import useWindowLoad from "hooks/useWindowLoad";
import SignupSSO from "features/signup/SignupSSO";
import { ConnectedServiceEnum } from "constants/Global";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import UserApi from "apis/UserApi";
import useAuthUser from "hooks/useAuthUser";
import useDataRef from "hooks/useDataRef";

function AuthGoogleIdentityService({
  isHidePrompt,
  userType,
  location,
  onSubmitting,
  onSuccess,
  onError,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const authUser = useAuthUser();

  const [ssoState, setSSOState] = useState(null);

  const isAuthenticated = !!authUser?.access_token;

  const [ssoUseMutation] = UserApi.useSsoUserMutation();

  const [isWindowLoaded, setWindowLoaded] = useState(
    document.readyState !== "loading"
  );

  useWindowLoad(() => setWindowLoaded(true));

  async function handleCallback(crendentialResponse) {
    try {
      onSubmitting?.();
      const token = crendentialResponse.credential;
      const data = await ssoUseMutation({
        data: {
          type: userType,
          location,
          token,
          provider: ConnectedServiceEnum.GOOGLE,
        },
      }).unwrap();

      if (data?.data?.access_token) {
        onSuccess?.();
        enqueueSnackbar(data?.message, { variant: "success" });
      } else {
        setSSOState({ token, provider: ConnectedServiceEnum.GOOGLE });
      }
    } catch (error) {
      onError?.(error);
      enqueueSnackbar(error.data?.message, { variant: "error" });
    }
  }

  const dataRef = useDataRef({ handleCallback });

  useEffect(() => {
    if (isWindowLoaded && !isAuthenticated) {
      gis.initialize({
        client_id: process.env.REACT_APP_GOOGLE_WEB_CLIENT_ID,
        context: "signin",
        ux_mode: "popup",
        nonce: "",
        // auto_select: "true",
        itp_support: "true",
        // login_uri: window.location.host.concat("/sso"),
        callback: (crendentialResponse) => {
          return dataRef.current.handleCallback(crendentialResponse);
        },
      });

      if (isHidePrompt) {
        gis.cancel();
      } else {
        gis.prompt();
      }
    }
  }, [dataRef, isAuthenticated, isHidePrompt, isWindowLoaded]);

  useEffect(() => {
    setSSOState((p) => (isAuthenticated ? null : p));
  }, [isAuthenticated]);

  return (
    <>
      {!isAuthenticated ? (
        <SignupSSO
          open={!!ssoState}
          token={ssoState?.token}
          provider={ssoState?.provider}
          onClose={() => setSSOState(null)}
        />
      ) : null}
    </>
  );
}

export default AuthGoogleIdentityService;
