import UserApi from "apis/UserApi";
import { useFormik } from "formik";
import * as yup from "yup";
import { useSnackbar } from "notistack";
import SignupLocation from "features/signup/SignupLocation";
import { UserTypeEnum } from "constants/Global";
import { Dialog, DialogContent } from "@mui/material";
import DialogTitleXCloseButton from "common/DialogTitleXCloseButton";
import { useSearchParams } from "react-router-dom";
import { urlSearchParamsExtractor } from "utils/URLUtils";

function SignupSSO(props) {
  const {
    onClose,
    provider: _provider,
    token: _token,
    type: _type,
    ...restProps
  } = props;

  const { enqueueSnackbar } = useSnackbar();

  const [searchParam] = useSearchParams();

  const { type, token, provider } = urlSearchParamsExtractor(searchParam, {
    provider: _provider ?? "",
    type: _type ?? "",
    token: _token ?? "",
  });

  const [ssoUseMutation] = UserApi.useSsoUserMutation();

  const formik = useFormik({
    initialValues: {
      provider,
      type,
      token,
      location: {
        country_code: "",
        // state_code: "",
        // city_name: "",
      },
    },
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.lazy((values) => {
      return yup.object({
        provider: yup.string().label("Provider").trim().required(),
        token: yup.string().label("SSO Token").trim().required(),
        type: yup
          .string()
          .label("Type")
          .trim()
          .required("Please select a specific user type"),
        location: yup
          .object({
            country_code: yup.mixed().label("Country").required(),
            // state_code: yup.mixed().label("State").required(),
            // city_name: yup.mixed().label("City").required(),
          })
          .required(),
      });
    }),
    onSubmit: async (values) => {
      try {
        const data = await ssoUseMutation({
          data: { ...values },
        }).unwrap();
        enqueueSnackbar(data?.message, { variant: "success" });

        handleClose();
      } catch (error) {
        enqueueSnackbar(error.data?.message, { variant: "error" });
      }
    },
  });

  const isClient = formik.values.type === UserTypeEnum.CLIENT;
  const isFreelancer = formik.values.type === UserTypeEnum.FREELANCER;

  const contentProps = {
    formik,
    isClient,
    isFreelancer,
  };

  function handleClose() {
    onClose?.();
  }

  return (
    <Dialog fullWidth {...restProps}>
      <DialogTitleXCloseButton onClose={handleClose}></DialogTitleXCloseButton>
      <DialogContent>
        <form className="block" onSubmit={formik.handleSubmit}>
          <SignupLocation {...contentProps} />,
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default SignupSSO;
