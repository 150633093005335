import useAuthUser from "hooks/useAuthUser";
import UserAvatar from "./UserAvatar";

/**
 *
 * @param {import("./UserAvatar").UserAvatarProps} props
 * @returns
 */
function AuthUserAvatar(props) {
  const authUser = useAuthUser();
  const name = authUser?.isClient ? authUser?.company_name : authUser?.lastname;

  return (
    <UserAvatar src={authUser?.photo} {...props}>
      {name?.[0]?.toUpperCase()}
    </UserAvatar>
  );
}

export default AuthUserAvatar;
