import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BsExclamationCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";

const Cookies = () => {
  const [showCookies, setShowCookies] = useState(false);

  useEffect(() => {
    const cookiesTimeout = setTimeout(() => {
      setShowCookies(true);
    }, 5000);

    const storedCookies = localStorage.getItem("sisenow_cookies");
    if (storedCookies === "true") {
      clearTimeout(cookiesTimeout);
    }
  }, [setShowCookies]);

  const onAddCookies = () => {
    localStorage.setItem("sisenow_cookies", "true");
    setShowCookies(false);
  };

  return (
    <>
      {showCookies && (
        <div className="animation fixed bottom-0 z-10 bg-[#f7f6fb] flex space-x-0 rounded-t-[40px] border border-primary-light px-5 py-10 flex-col space-y-5 lg:space-y-0 lg:py-15 lg:px-24 lg:space-x-32 lg:flex-row">
          <div className="flex justify-center space-x-3">
            <div className="mt-1 w-[30%] text-primary-main md:w-[5%]">
              <BsExclamationCircleFill width={50} />
            </div>
            <h1>
              By clicking <span className="font-bold">"Accept"</span>, you agree
              to the storing of cookies on your device to enhance site
              navigation, analyze site usage, and assist in our marketing
              efforts. View our{" "}
              <Link to="#">
                <u>Privacy Policy</u>
              </Link>{" "}
              for more information.
            </h1>
          </div>

          <div className=" flex flex-rol justify-center space-y-0 space-x-5 lg:space-x-0 lg:space-y-3 lg:flex-col">
            <Button onClick={onAddCookies}>Accept</Button>
            <Button color="error" onClick={() => setShowCookies(false)}>
              Decline
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default Cookies;
