import { RouteEnum } from "constants/RouteConstants";
import { isUserUnverified } from "features/user/UserUtils";
import useAuthUser from "hooks/useAuthUser";
import { Navigate, useLocation } from "react-router-dom";

function RedirectPublic() {
  const location = useLocation();

  const authUser = useAuthUser();

  return (
    <Navigate
      to={
        isUserUnverified(authUser)
          ? RouteEnum.SIGNUP_VERIFICATION_REQUEST.concat(
              "?username=",
              authUser?.email_address,
              "&id=",
              authUser?.id
            )
          : RouteEnum.LOGIN
      }
      state={{ previousLocation: location }}
      replace
    />
  );
}

export default RedirectPublic;
