import { useEffect } from "react";
import useDataRef from "./useDataRef";

function useWindowLoad(load, unload) {
  const dataRef = useDataRef({ load, unload });

  useEffect(() => {
    window.addEventListener("load", dataRef.current.load);
    window.addEventListener("unload", dataRef.current.unload);

    return () => {
      window.removeEventListener("load", dataRef.current.load);
      window.removeEventListener("unload", dataRef.current.unload);
    };
  }, [dataRef]);
}

export default useWindowLoad;
