import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Container,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MediaQueryBreakpointEnum } from "constants/Global";
import { BsApple, BsFacebook, BsInstagram, BsYoutube } from "react-icons/bs";
import { GrAndroid } from "react-icons/gr";
import { useMemo } from "react";
import CategoryApi from "apis/CategoryApi";
import LoadingContent from "./LoadingContent";
import MuiRouterLink from "./MuiRouterLink";
import { RouteEnum } from "constants/RouteConstants";
import { Link } from "react-router-dom";

function PageFooter(props) {
  const ismd = useMediaQuery(MediaQueryBreakpointEnum.md);

  const levelOneCategoriesQueryResult = CategoryApi.useGetCategoriesQuery(
    useMemo(() => ({ params: { level: 1 } }), [])
  );
  const levelOneCategories = levelOneCategoriesQueryResult.data?.data || [];

  const expandableFooter = [
    {
      name: "Categories",
      list: levelOneCategories.map((category) => ({
        children: category?.name,
        component: MuiRouterLink,
        to: RouteEnum.PROFILE_FREELANCERS.concat("?categories=", category?.id),
      })),
      loadingContentProps: {
        loading: levelOneCategoriesQueryResult.isLoading,
        error: levelOneCategoriesQueryResult.isError,
        onReload: levelOneCategoriesQueryResult.refetch,
      },
    },
    {
      name: "Talent",
      list: [
        { children: "How to Get a Job" },
        { children: "Direct Contracts" },
        { children: "How to update profile" },
      ],
    },

    {
      name: "Clients",
      list: [
        { children: "How to Hire" },
        { children: "Talent Marketplace" },
        { children: "Project Catalog" },
        { children: "Talent Scout" },
        { children: "Payment Method" },
        { children: "Direct Contracts" },
        { children: "Hire Worldwide" },
      ],
    },

    {
      name: "About Us",
      list: [
        { children: "Leadership" },
        { children: "Investor Relations" },
        { children: "Careers" },
        { children: "Our Impact" },
        { children: "Press" },
        { children: "Contact Us" },
      ],
    },

    {
      name: "Support",
      list: [
        { children: "Help & Support", onClick: () => window.Tawk_API.toggle() },
        { children: "Trust & Safety" },
        { children: "FAQs" },
        { children: "Community" },
      ],
    },
  ];

  return (
    <>
      <div className="bg-primary-dark text-white">
        <Container maxWidth="xl">
          {ismd ? (
            <div className="flex flex-wrap justify-between items-start py-16 pb-32">
              {expandableFooter.map(
                ({ name, list, loadingContentProps }, index) => {
                  return (
                    <div
                      key={index}
                      className=" flex flex-col gap-2 justify-center items-center"
                    >
                      <Typography variant="h4" className="my-20">
                        {name}
                      </Typography>
                      <LoadingContent {...loadingContentProps}>
                        {() => (
                          <>
                            {list.map((props, index) => (
                              <Typography
                                key={index}
                                className="text-primary-contrastText cursor-pointer"
                                {...props}
                              />
                            ))}
                          </>
                        )}
                      </LoadingContent>
                    </div>
                  );
                }
              )}
            </div>
          ) : (
            <div className="py-10">
              {expandableFooter.map(({ name, list, loadingContentProps }) => (
                <Accordion
                  key={name}
                  disableGutters
                  className="w-full bg-primary-dark text-white border-white"
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon className="text-white" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="h5" className="border-white">
                      {name}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className="flex flex-col gap-3">
                    <LoadingContent {...loadingContentProps}>
                      {() => (
                        <>
                          {list.map((props, index) => (
                            <Typography
                              key={index}
                              className="text-primary-contrastText cursor-pointer"
                              {...props}
                            />
                          ))}
                        </>
                      )}
                    </LoadingContent>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          )}
        </Container>
        <div className="pt-20 pb-5 ">
          <div className="py-4 px-24 border-y-white border-y-2 flex justify-center md:justify-between items-center">
            {ismd && (
              <ul className="flex w-1/12 md:justify-between justify-center">
                <li>
                  <BsYoutube className="text-2xl" />
                </li>
                <li>
                  <BsFacebook className="text-2xl" />
                </li>
                <li>
                  <BsInstagram className="text-2xl" />
                </li>
              </ul>
            )}
            <ul>
              <li className="text-center">
                © Ayopey Global Services Limited. 2024
              </li>
            </ul>
            {ismd && (
              <ul className="flex gap-2 py-4 justify-between">
                <li>
                  <GrAndroid className="text-2xl" />
                </li>
                <li>
                  <BsApple className="text-2xl" />
                </li>
              </ul>
            )}
          </div>
          {ismd && (
            <div className="flex justify-center">
              <ul className="w-3/5 mt-4 font-semibold flex justify-between">
                <Link to={RouteEnum.TERM_OF_SERVICE}>
                  <li>Terms of Service </li>
                </Link>
                <Link to={RouteEnum.PRIVACY_POLICY}>
                  <li> Privacy Policy</li>
                </Link>
                <li>Cookie Setting</li>
                <li>Accessibility</li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default PageFooter;
