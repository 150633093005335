import {
  CircularProgress,
  InputAdornment,
  MenuItem,
  TextField,
} from "@mui/material";
import CurrencyApi from "apis/CurrencyApi";
import UserApi from "apis/UserApi";
import { setCurrencyAction } from "configs/StoreSliceConfig";
import useAuthUser from "hooks/useAuthUser";
import useCurrency from "hooks/useCurrency";
import { useDispatch } from "react-redux";
import { normalizeArray } from "utils/ObjectUtils";

function ProfileCurrencyUpdate(props) {
  const { ...restProps } = props;

  const dispatch = useDispatch();

  const authUser = useAuthUser();

  const currency = useCurrency();

  const [updateUserSelfMutation, updateUserSelfMutationResult] =
    UserApi.useUpdateUserSelfMutation();

  const currenciesQueryResult = CurrencyApi.useGetCurrenciesQuery();

  const currencies = currenciesQueryResult.data?.data;

  const normalizedCurrencies = normalizeArray(currencies, {
    getKey: ({ code }) => code,
  });

  async function handleChange(e) {
    const code = e.target.value;
    const previousCurrency = currency;
    try {
      dispatch(setCurrencyAction(normalizedCurrencies[code]));

      if (authUser) {
        await updateUserSelfMutation({
          data: { currency_code: code },
        }).unwrap();
      }
    } catch (error) {
      dispatch(setCurrencyAction(previousCurrency));
    }
  }

  return (
    <TextField
      select
      SelectProps={{ displayEmpty: true }}
      value={currency?.code || ""}
      onChange={handleChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            {updateUserSelfMutationResult.isLoading ? (
              // <div className="absolute left-2 top-1/2 -translate-y-1/2">
              <CircularProgress size={12} />
            ) : // </div>
            null}
          </InputAdornment>
        ),
      }}
      {...restProps}
    >
      <MenuItem disabled value="">
        Select
      </MenuItem>
      {currencies?.map((currency) => (
        <MenuItem key={currency?.code} value={currency?.code}>
          {currency?.code}
        </MenuItem>
      ))}
    </TextField>
  );
}

export default ProfileCurrencyUpdate;
