import { forwardRef } from "react";
import { CircularProgress } from "@mui/material";
import clsx from "clsx";

const LoadingIndicator = forwardRef(
  /**
   *
   * @param {import("@mui/material").CircularProgressProps} props
   */
  function LoadingIndicator(props, ref) {
    return (
      <CircularProgress
        ref={ref}
        {...props}
        className={clsx("outline-none", props.className)}
      />
    );
  }
);

export default LoadingIndicator;
