import { SoftwrkApi } from "configs/StoreQueryConfig";
import { StoreQueryTagEnum } from "constants/StoreConstants";
import { provideTags } from "utils/QueryUtils";

const CURRENCY_BASE_URL = "/api/v1/currencies";

export const CurrencyApi = SoftwrkApi.injectEndpoints({
  endpoints: (builder) => ({
    getCurrencies: builder.query({
      query: (config) => ({
        url: `${CURRENCY_BASE_URL}`,
        ...config,
      }),
      providesTags: (data, error) =>
        provideTags(StoreQueryTagEnum.CURRENCY, data?.data, error),
    }),
    getCurrency: builder.query({
      query: ({ path, ...config }) => ({
        url: `${CURRENCY_BASE_URL}/${path?.id}`,
        ...config,
      }),
      providesTags: (data, error) =>
        provideTags(StoreQueryTagEnum.CURRENCY, [data?.data], error),
    }),
    getDefaultCurrency: builder.query({
      query: (config) => ({
        url: `${CURRENCY_BASE_URL}/default`,
        ...config,
      }),
      providesTags: (data, error) =>
        provideTags(StoreQueryTagEnum.CURRENCY, [data?.data], error),
    }),
  }),
});

export default CurrencyApi;
