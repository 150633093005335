export const isUndefined = (val) => val === undefined;

export const isNull = (val) => val === null;

export const isNil = (val) => val === undefined || val === null;

export const isBoolean = (val) => typeof val === "boolean";

export const isNumber = (val) => typeof val === "number" && !Number.isNaN(val);

export const isBigInt = (val) => typeof val === "bigint";

export const isString = (val) => typeof val === "string";

export const isSymbol = (val) => typeof val === "symbol";

export const isPrimitive = (val) => Object(val) !== val;

export const isObject = (obj) => obj === Object(obj);

export const isArray = (arr) => Array.isArray(arr);

export const isPlainObject = (val) =>
  !!val && typeof val === "object" && val.constructor === Object;

export const isFunction = (val) => typeof val === "function";

export const isAsyncFunction = (val) =>
  Object.prototype.toString.call(val) === "[object AsyncFunction]";

export const isGeneratorFunction = (val) =>
  Object.prototype.toString.call(val) === "[object GeneratorFunction]";

export const getType = (v) =>
  v === undefined ? "undefined" : v === null ? "null" : v.constructor.name;

export const isOfType = (type, val) =>
  ([undefined, null].includes(val) && val === type) ||
  val.constructor.name === type;
