import { lazy, useMemo, useState } from "react";
import "./App.css";
import AppThemeProvider from "AppThemeProvider";
import { Container, Icon, IconButton } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { notistackRef } from "constants/RefConstants";
import Suspense from "common/Suspense";
import useAuthUser from "hooks/useAuthUser";
import LoadingModal from "common/LoadingModal";
import useLoadingModal from "hooks/useLoadingModal";
import useScrollToTop from "hooks/useScrollToTop";
import { matchPath, useLocation, useRoutes } from "react-router-dom";
import UserApi from "apis/UserApi";
import { RouteEnum } from "constants/RouteConstants";
import { configureRoutes } from "utils/RouteUtils";
import ProtectedPageHeader from "common/ProtectedPageHeader";
import LoadingContent from "common/LoadingContent";
import PageFooter from "common/PageFooter";
import PublicPageHeader from "common/PublicPageHeader";
import Cookies from "common/Cookie";
import RedirectProtected from "common/RedirectProtected";
import RedirectPublic from "common/RedirectPublic";
import AuthGoogleIdentityService from "features/auth/AuthGoogleIdentityService";
import CurrencyApi from "apis/CurrencyApi";

function App() {
  const { isLoadingModal } = useLoadingModal();

  const authUser = useAuthUser();

  const location = useLocation();

  const [appBarRect, setAppBarRect] = useState({ width: 0, height: 0 });

  const isAuthenticated = !!authUser?.access_token;

  CurrencyApi.useGetDefaultCurrencyQuery();

  const authUserProfileQueryResults = UserApi.useGetAuthUserProfileQuery(
    undefined,
    { skip: !isAuthenticated }
  );

  const routeConfig = useMemo(
    () =>
      [
        ...(authUser?.access_token ? [] : [{ path: RouteEnum.HOME }]),
        { path: RouteEnum.LOGIN, noSSO: true },
        { path: RouteEnum.SIGNUP, noSSO: true },
        { path: RouteEnum.SIGNUP_VERIFICATION_REQUEST },
        { path: RouteEnum.SIGNUP_VERIFICATION },
        { path: RouteEnum.SIGNUP_CONGRATULATIONS },
        { path: RouteEnum.PASSWORD_RESET_REQUEST },
        { path: RouteEnum.PASSWORD_RESET },
        { path: RouteEnum.PAYMENTS_PAY, header: false },
        { path: RouteEnum.PAYMENTS_PAY_RETURN, header: false },
      ].find((route) => matchPath(route, location.pathname)),
    [authUser?.access_token, location.pathname]
  );

  const routes = useRoutes(
    useMemo(
      () => getRoutes({ access_token: authUser?.access_token }),
      [authUser?.access_token]
    )
  );

  useScrollToTop(location);

  const PageHeader = authUser ? ProtectedPageHeader : PublicPageHeader;

  return (
    <AppThemeProvider>
      <SnackbarProvider
        ref={notistackRef}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
        preventDuplicate
        action={(key) => (
          <IconButton
            onClick={() => {
              notistackRef.current.closeSnackbar(key);
            }}
            color="inherit"
            size="small"
          >
            <Icon>close</Icon>
          </IconButton>
        )}
      >
        <LoadingContent
          loading={authUserProfileQueryResults.isLoading}
          error={authUserProfileQueryResults.isError}
          onReload={authUserProfileQueryResults.refetch}
        >
          {() => (
            <>
              {routeConfig && !routeConfig?.header && !routeConfig?.content ? (
                <>{<Suspense>{routes}</Suspense>}</>
              ) : (
                <>
                  {(routeConfig ? !!routeConfig.header : true) && (
                    <PageHeader hideRectSpacing onRectChange={setAppBarRect} />
                  )}
                  {(routeConfig ? !!routeConfig.content : true) && (
                    <Container
                      className="min-h-full pb-8"
                      style={{ paddingTop: appBarRect?.height + 22 }}
                    >
                      <Suspense>{routes}</Suspense>
                    </Container>
                  )}
                  {(routeConfig ? !!routeConfig.footer : true) && (
                    <PageFooter />
                  )}
                </>
              )}
              {(routeConfig ? !routeConfig.noSSO : true) && (
                <AuthGoogleIdentityService />
              )}
            </>
          )}
        </LoadingContent>
      </SnackbarProvider>
      <LoadingModal open={isLoadingModal} />
      <Cookies />
    </AppThemeProvider>
  );
}

export default App;

function getRoutes({ access_token }) {
  return configureRoutes([
    ...(access_token
      ? [
          {
            path: "*",
            element: <RedirectProtected />,
          },
          {
            path: RouteEnum.DASHBOARD,
            element: lazy(() => import("pages/dashboard/Dashboard")),
          },
          {
            path: RouteEnum.SETTINGS.concat("/*"),
            element: lazy(() => import("pages/setting/Setting")),
          },
          {
            path: RouteEnum.CONTENT_CLIENT_GETTING_STARTED,
            element: lazy(() =>
              import("pages/content/ContentClientGettingStarted")
            ),
          },
          {
            path: RouteEnum.HOW_IT_WORKS,
            element: lazy(() => import("pages/how-it-works/HowItWorks")),
          },
          {
            path: RouteEnum.MESSAGES.concat("/*"),
            element: lazy(() => import("pages/message/Message")),
          },
          {
            path: RouteEnum.CONTRACTS.concat("/*"),
            element: lazy(() => import("pages/contract/Contract")),
          },
          {
            path: RouteEnum.PROPOSALS.concat("/*"),
            element: lazy(() => import("pages/proposal/Proposal")),
          },
        ]
      : [
          {
            path: "*",
            element: <RedirectPublic />,
          },
          {
            path: RouteEnum.HOME,
            element: lazy(() => import("pages/home/Home")),
          },
          {
            path: RouteEnum.LOGIN,
            element: lazy(() => import("pages/login/Login")),
          },
          {
            path: RouteEnum.SIGNUP,
            element: lazy(() => import("pages/signup/Signup")),
          },
          {
            path: RouteEnum.SIGNUP_VERIFICATION_REQUEST,
            element: lazy(() =>
              import("pages/signup/SignupVerificationRequest")
            ),
          },
          {
            path: RouteEnum.SIGNUP_VERIFICATION,
            element: lazy(() => import("pages/signup/SignupVerification")),
          },
          {
            path: RouteEnum.SIGNUP_CONGRATULATIONS,
            element: lazy(() => import("pages/signup/SignUpCongratulations")),
          },
          {
            path: RouteEnum.PASSWORD_RESET_REQUEST,
            element: lazy(() => import("pages/auth/AuthPasswordResetRequest")),
          },
          {
            path: RouteEnum.PASSWORD_RESET,
            element: lazy(() => import("pages/auth/AuthPasswordReset")),
          },
        ]),
    {
      path: RouteEnum.PROFILE.concat("/*"),
      element: lazy(() => import("pages/profile/Profile")),
    },
    {
      path: RouteEnum.PROJECTS.concat("/*"),
      element: lazy(() => import("pages/project/Project")),
    },
    {
      path: RouteEnum.JOBS.concat("/*"),
      element: lazy(() => import("pages/job/Job")),
    },
    {
      path: RouteEnum.REPORTS.concat("/*"),
      element: lazy(() => import("pages/report/Report")),
    },
    {
      path: RouteEnum.PRIVACY_POLICY,
      element: lazy(() => import("features/policies/privacy-policy")),
    },
    {
      path: RouteEnum.TERM_OF_SERVICE,
      element: lazy(() => import("features/policies/term-of-service")),
    },
    {
      path: RouteEnum.PAYMENTS.concat("/*"),
      element: lazy(() => import("pages/payment/Payment")),
    },
  ]);
}

//Stats https://www.clicktechtips.com/tips-to-earn-money-online-on-upwork-in-2015/
