import { SoftwrkApi } from "configs/StoreQueryConfig";

const BASE_URL = "/api/v1/categories";

export const CategoryApi = SoftwrkApi.injectEndpoints({
  endpoints: (builder) => ({
    getCategories: builder.query({
      query: (config) => {
        return {
          url: `${BASE_URL}`,
          ...config,
        };
      },
    }),
    getCategoriesParents: builder.query({
      query: (config) => {
        return {
          url: `${BASE_URL}/parent-hierarchy`,
          ...config,
        };
      },
    }),

    getCategory: builder.query({
      query: ({ path, ...config }) => {
        return {
          url: `${BASE_URL}/${path.id}`,
          ...config,
        };
      },
    }),
    getCategoryParents: builder.query({
      query: ({ path, ...config }) => {
        return {
          url: `${BASE_URL}/${path.id}/parent-hierarchy`,
          ...config,
        };
      },
    }),
  }),
});

export default CategoryApi;
