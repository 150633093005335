import {
  Button,
  Checkbox,
  CircularProgress,
  FormHelperText,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { UserTypeEnum } from "constants/Global";
import {
  getCheckFieldFormikProps,
  getTextFieldFormikHelperTextAndErrorProps,
} from "utils/FormikUtils";
import LoginXSignupTitle from "features/auth/AuthTitle";
import CountryAutocomplete from "common/CountryAutocomplete";
import StateAutocomplete from "common/StateAutocomplete";
import CityAutocomplete from "common/CityAutocomplete";
import { LoadingButton } from "@mui/lab";
import MuiRouterLink from "common/MuiRouterLink";
import { RouteEnum } from "constants/RouteConstants";

function SignupLocation({ formik }) {
  const typeFormikHelperTextAndError =
    getTextFieldFormikHelperTextAndErrorProps(formik, "type");

  return (
    <>
      <LoginXSignupTitle>Join Sisenow</LoginXSignupTitle>
      <div className="my-2">
        <Typography variant="overline" className="text-center block font-bold">
          Signup as a
        </Typography>
        <ToggleButtonGroup
          color="primary"
          fullWidth
          // orientation="vertical"
          value={formik.values.type}
          exclusive
          onChange={(_, value) => formik.setFieldValue("type", value || "")}
          aria-label="Platform"
        >
          <ToggleButton value={UserTypeEnum.CLIENT}>Client</ToggleButton>
          <ToggleButton value={UserTypeEnum.FREELANCER}>
            Freelancer
          </ToggleButton>
        </ToggleButtonGroup>
        <FormHelperText
          error={typeFormikHelperTextAndError.error}
          className="text-center"
        >
          {typeFormikHelperTextAndError.helperText}
        </FormHelperText>
      </div>
      <CountryAutocomplete
        value={formik.values.location.country_code}
        onChange={(_, value) => {
          formik.setFieldValue(
            "location.country_code",
            value?.iso2 || value || ""
          );
        }}
        inputValueQueryParams={{ _sort: "name:asc" }}
        renderInput={(params) => (
          <TextField
            label="Country"
            margin="normal"
            required
            placeholder="Type in your location & select from Dropdown"
            {...getTextFieldFormikHelperTextAndErrorProps(
              formik,
              "location.country_code",
              "Type in your country & select from Dropdown"
            )}
            {...params}
          />
        )}
      />
      {/* <StateAutocomplete
        value={formik.values.location.state_code}
        onChange={(_, value) => {
          formik.setFieldValue(
            "location.state_code",
            value?.code || value || ""
          );
        }}
        inputValueQueryParams={{
          _sort: "name:asc",
          country_code: formik.values.location.country_code || undefined,
        }}
        renderInput={(params) => (
          <TextField
            label="State"
            margin="normal"
            required
            placeholder="Type in your location & select from Dropdown"
            {...getTextFieldFormikHelperTextAndErrorProps(
              formik,
              "location.state_code",
              "Type in your state & select from Dropdown"
            )}
            {...params}
          />
        )}
      /> */}
      {/* <CityAutocomplete
        value={formik.values.location.city_name}
        onChange={(_, value) => {
          formik.setFieldValue(
            "location.city_name",
            value?.name || value || ""
          );
        }}
        inputValueQueryParams={{
          _sort: "name:asc",
          country_code: formik.values.location.country_code || undefined,
          state_code: formik.values.location.state_code || undefined,
        }}
        renderInput={(params) => (
          <TextField
            label="City"
            margin="normal"
            required
            placeholder="Type in your location & select from Dropdown"
            {...getTextFieldFormikHelperTextAndErrorProps(
              formik,
              "location.city_name",
              "Type in your city & select from Dropdown"
            )}
            {...params}
          />
        )}
      /> */}
      <div className="mt-6 flex items-center">
        <Checkbox {...getCheckFieldFormikProps(formik, "accept_terms")} />
        <Typography variant="body2" className="text-center">
          By clicking you agree to our{" "}
          <MuiRouterLink to={RouteEnum.TERM_OF_SERVICE}>
            Terms and Conditions
          </MuiRouterLink>
        </Typography>
      </div>
      <LoadingButton
        className="my-6"
        fullWidth
        type="submit"
        loading={formik.isSubmitting}
        loadingPosition="start"
        startIcon={<></>}
        size="large"
      >
        Continue
      </LoadingButton>
    </>
  );
}

export default SignupLocation;
