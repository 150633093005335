import { logoutAction } from "configs/StoreActionConfig";
import { SoftwrkApi } from "configs/StoreQueryConfig";
import { UserTypeEnum } from "constants/Global";
import { StoreQueryTagEnum } from "constants/StoreConstants";
import { invalidateTags, provideTags } from "utils/QueryUtils";

const BASE_URL = "/api/v1/users";

export const UserApi = SoftwrkApi.injectEndpoints({
  endpoints: (builder) => ({
    signup: builder.mutation({
      query: (config) => ({
        url: `${BASE_URL}/signup`,
        method: "POST",
        ...config,
      }),
    }),
    ssoUser: builder.mutation({
      query: (config) => ({
        url: `${BASE_URL}/sso`,
        method: "POST",
        ...config,
      }),
    }),
    login: builder.mutation({
      // query: (config) => ({
      //   url: `${BASE_URL}/signin`,
      //   method: "POST",
      //   ...config,
      // }),
      queryFn: async (config, _, __, baseQuery) => {
        const queryResult = await baseQuery({
          url: `${BASE_URL}/signin`,
          method: "POST",
          ...config,
        });

        if (queryResult.error) {
          return queryResult;
        }

        if (
          !Object.values(UserTypeEnum).includes(
            queryResult.data?.data.user?.type
          )
        ) {
          return {
            error: {
              data: {
                status: 400,
                message: "Invalid username or password",
                errors: [],
                name: "ValidationException",
                path: "/api/v1/users/signin",
              },
            },
          };
        }

        return queryResult;
      },
    }),
    checkUserAvailability: builder.mutation({
      query: (config) => ({
        url: `${BASE_URL}/availability`,
        ...config,
      }),
    }),
    checkEmailAvailability: builder.mutation({
      query: (config) => ({
        url: `${BASE_URL}/email-availability`,
        ...config,
      }),
    }),
    checkUsernameAvailability: builder.mutation({
      query: (config) => ({
        url: `${BASE_URL}/username-availability`,
        ...config,
      }),
    }),
    verifyUser: builder.mutation({
      query: (config) => ({
        url: `${BASE_URL}/verify`,
        method: "POST",
        ...config,
      }),
      invalidatesTags: (_, error) =>
        error ? invalidateTags(StoreQueryTagEnum.USER) : [],
    }),
    resendUserVerification: builder.mutation({
      query: (config) => ({
        url: `${BASE_URL}/verify/send`,
        method: "POST",
        ...config,
      }),
    }),
    getUsers: builder.query({
      query: (config) => ({
        url: `${BASE_URL}`,
        ...config,
      }),
      providesTags: (data, error) =>
        provideTags(StoreQueryTagEnum.USER, data?.data, error),
    }),
    getSavedUsers: builder.query({
      query: (config) => ({
        url: `${BASE_URL}/saved`,
        ...config,
      }),
      providesTags: (data, error) =>
        provideTags(StoreQueryTagEnum.USER, data?.data, error),
    }),
    getUser: builder.query({
      query: ({ path, ...config }) => ({
        url: `${BASE_URL}/${path.id}`,
        ...config,
      }),
      providesTags: (data, error) =>
        provideTags(StoreQueryTagEnum.USER, [data?.data], error),
    }),
    getAuthUserProfile: builder.query({
      queryFn: (config, { getState }, ___, baseQuery) => {
        return baseQuery({
          url: `${BASE_URL}/${getState().global?.authUser?.id}`,
          ...config,
          params: { _expand: true },
        });
      },
      providesTags: (data, error) =>
        provideTags(StoreQueryTagEnum.USER, data?.data, error),
    }),
    updateUser: builder.mutation({
      query: ({ path, ...config }) => ({
        url: `${BASE_URL}/${path.id}`,
        method: "PUT",
        ...config,
      }),
      invalidatesTags: (_, error, { path }) =>
        invalidateTags(StoreQueryTagEnum.USER, [path.id], error),
    }),
    updateUserSelf: builder.mutation({
      query: ({ path, ...config }) => ({
        url: `${BASE_URL}/self`,
        method: "PUT",
        ...config,
      }),
      invalidatesTags: (_, error, { path }) =>
        invalidateTags(StoreQueryTagEnum.USER, error),
    }),
    addUserPhoneNumber: builder.mutation({
      query: ({ path, ...config }) => ({
        url: `${BASE_URL}/self/phone-numbers`,
        method: "POST",
        ...config,
      }),
      invalidatesTags: (_, error, { path }) =>
        invalidateTags(StoreQueryTagEnum.USER, [path.id], error),
    }),
    resendUserPhoneNumberVerification: builder.mutation({
      query: ({ path, ...config }) => ({
        url: `${BASE_URL}/self/phone-numbers/verify/send`,
        method: "POST",
        ...config,
      }),
    }),
    verifyUserPhoneNumber: builder.mutation({
      query: ({ path, ...config }) => ({
        url: `${BASE_URL}/self/phone-numbers/verify`,
        method: "POST",
        ...config,
      }),
      invalidatesTags: (_, error, { path }) =>
        invalidateTags(StoreQueryTagEnum.USER, [path.id], error),
    }),
    removeUserPhoneNumber: builder.mutation({
      query: ({ path, ...config }) => ({
        url: `${BASE_URL}/self/phone-numbers`,
        method: "DELETE",
        ...config,
      }),
      invalidatesTags: (_, error, { path }) =>
        invalidateTags(StoreQueryTagEnum.USER, [path.id], error),
    }),
    changeUserPassword: builder.mutation({
      query: ({ path, ...config }) => ({
        url: `${BASE_URL}/password/change`,
        method: "PUT",
        ...config,
      }),
      invalidatesTags: (_, error, { path }) =>
        invalidateTags(StoreQueryTagEnum.USER, undefined, error),
    }),
    updateUserVerifiedDetails: builder.mutation({
      query: ({ path, ...config }) => ({
        url: `${BASE_URL}/verified-details`,
        method: "PUT",
        ...config,
      }),
      invalidatesTags: (_, error, { path }) =>
        invalidateTags(StoreQueryTagEnum.USER, undefined, error),
    }),
    updateUserVisibility: builder.mutation({
      query: ({ path, ...config }) => ({
        url: `${BASE_URL}/visibility`,
        method: "PATCH",
        ...config,
      }),
      invalidatesTags: (_, error, { path }) =>
        invalidateTags(StoreQueryTagEnum.USER, undefined, error),
    }),
    updateUserExperienceLevel: builder.mutation({
      query: ({ path, ...config }) => ({
        url: `${BASE_URL}/experience-level`,
        method: "PATCH",
        ...config,
      }),
      invalidatesTags: (_, error, { path }) =>
        invalidateTags(StoreQueryTagEnum.USER, undefined, error),
    }),
  }),
});

export default UserApi;
