import { SoftwrkApi } from "configs/StoreQueryConfig";
import { StoreQueryTagEnum } from "constants/StoreConstants";
import { provideTags } from "utils/QueryUtils";

const STATE_BASE_URL = "/api/v1/states";

export const StateApi = SoftwrkApi.injectEndpoints({
  endpoints: (builder) => ({
    getStates: builder.query({
      query: (config) => ({
        url: `${STATE_BASE_URL}`,
        ...config,
      }),
      providesTags: (data, error) =>
        provideTags(StoreQueryTagEnum.STATE, data?.data, error),
    }),
    getState: builder.query({
      query: ({ path, ...config }) => ({
        url: `${STATE_BASE_URL}/${path?.id}`,
        ...config,
      }),
      providesTags: (data, error) =>
        provideTags(StoreQueryTagEnum.STATE, [data?.data], error),
    }),
  }),
});

export default StateApi;
