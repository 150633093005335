let _isLoaded = false;

window.onGoogleLibraryLoad = () => {
  _isLoaded = true;
};

export function isLoaded() {
  return _isLoaded;
}

/**
 *
 * @param {*} idConfiguration
 */
export function initialize(idConfiguration) {
  window.google?.accounts?.id?.initialize(idConfiguration);
}

/**
 *
 * @param {(notification: any) => void} callback
 */
export function prompt(callback) {
  window.google?.accounts?.id?.prompt(callback);
}

/**
 *
 * @param {HTMLElement} parentEl
 * @param {*} options
 */
export function renderButton(parentEl, options) {
  window.google?.accounts?.id?.renderButton(parentEl, options);
}

export function disableAutoSelect() {
  window.google?.accounts?.id?.disableAutoSelect();
}

export function cancel() {
  window.google?.accounts?.id?.cancel();
}

/**
 *
 * @param {string} parentEl
 * @param {(done: any) => void} callback
 */
export function revoke(idToken, callback) {
  window.google?.accounts?.id?.revoke(idToken, callback);
}

const gis = {
  initialize,
  prompt,
  renderButton,
  disableAutoSelect,
  cancel,
  revoke,
  isLoaded,
};

export default gis;

/**
 * @typedef {{
 * initialize: (idConfiguration: any) => void;
 * prompt: (callback: (notification: any) => void) => void;
 * renderButton: (parant: HTMLElement, options: any) => any;
 * disableAutoSelect: () => void;
 * storeCredential: (crendential: Credential, callback: () => void);
 * cancel: () => void;
 * revoke: (idToken: string, callback: (done: any) => void) => void;
 * }} GoogleIdentityService
 */
