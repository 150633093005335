import { createSlice } from "@reduxjs/toolkit";
import { logoutAction } from "./StoreActionConfig";
import UserApi from "apis/UserApi";
import { UserTypeEnum } from "constants/Global";
import CurrencyApi from "apis/CurrencyApi";

export const globalInitialState = {
  themeMode: "light", // 'dark'| 'light' | 'media'
  isLoadingModal: false,
  authUser: null,
  defaultCurrency: null,
  currency: null,
};

const slice = createSlice({
  name: "global",
  initialState: globalInitialState,
  reducers: {
    toggleLoadingModalAction: (state, { payload }) => {
      state.isLoadingModal =
        payload !== undefined ? !!payload : !state.isLoadingModal;
    },
    setCurrencyAction: (state, { payload }) => {
      state.currency = payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(logoutAction, () => ({ ...globalInitialState }))
      .addMatcher(
        CurrencyApi.endpoints.getDefaultCurrency.matchFulfilled,
        (state, { payload }) => {
          state.defaultCurrency = payload.data;
        }
      )
      .addMatcher(
        UserApi.endpoints.login.matchFulfilled,
        (state, { payload }) => {
          state.authUser = {
            access_token: payload.data?.access_token,
            ...payload.data?.user,
            isClient: payload.data?.user?.type === UserTypeEnum.CLIENT,
            isFreelancer: payload.data?.user?.type === UserTypeEnum.FREELANCER,
          };
          state.currency = state.authUser.currency;
        }
      )
      .addMatcher(
        UserApi.endpoints.ssoUser.matchFulfilled,
        (state, { payload }) => {
          if (payload.data?.access_token) {
            state.authUser = {
              access_token: payload.data?.access_token,
              ...payload.data?.user,
              isClient: payload.data?.user?.type === UserTypeEnum.CLIENT,
              isFreelancer:
                payload.data?.user?.type === UserTypeEnum.FREELANCER,
            };
            state.currency = state.authUser.currency;
          }
        }
      )
      .addMatcher(
        UserApi.endpoints.signup.matchFulfilled,
        (state, { payload }) => {
          // state.authUser = {
          //   access_token: payload.data?.access_token,
          //   ...payload.data?.user,
          // };
        }
      )
      .addMatcher(
        UserApi.endpoints.getAuthUserProfile.matchFulfilled,
        (state, { payload }) => {
          state.authUser = { ...state.authUser, ...payload.data };
          state.currency = state.authUser.currency;
        }
      ),
});

export const { toggleLoadingModalAction, setCurrencyAction } = slice.actions;

export default slice;

export function getGlobalSliceStorageState({ authUser }) {
  return { authUser };
}
