import { SoftwrkApi } from "configs/StoreQueryConfig";
import { StoreQueryTagEnum } from "constants/StoreConstants";
import { provideTags } from "utils/QueryUtils";

const COUNTRY_BASE_URL = "/api/v1/countries";

export const CountryApi = SoftwrkApi.injectEndpoints({
  endpoints: (builder) => ({
    getCountries: builder.query({
      query: (config) => ({
        url: `${COUNTRY_BASE_URL}`,
        ...config,
      }),
      providesTags: (data, error) =>
        provideTags(StoreQueryTagEnum.COUNTRY, data?.data, error),
    }),
    getCountry: builder.query({
      query: ({ path, ...config }) => ({
        url: `${COUNTRY_BASE_URL}/${path?.id}`,
        ...config,
      }),
      providesTags: (data, error) =>
        provideTags(StoreQueryTagEnum.COUNTRY, [data?.data], error),
    }),
  }),
});

export default CountryApi;
