import CurrencyApi from "apis/CurrencyApi";
import useDefaultCurrency from "./useDefaultCurrency";
import { useMemo } from "react";
import { useSelector } from "react-redux";

function useCurrency(code) {
  const defaultCurrency = useDefaultCurrency();
  const currency = useSelector((state) => state.global.currency);

  const isSameCurrency = code === currency?.code;

  const currencyQueryResult = CurrencyApi.useGetCurrencyQuery(
    useMemo(() => ({ path: { id: code } }), [code]),
    { skip: !(code && !isSameCurrency) }
  );

  const fetchedCurrency = currencyQueryResult.data?.data;

  const resolvedCurrency =
    (fetchedCurrency && !isSameCurrency ? fetchedCurrency : null) ??
    currency ??
    defaultCurrency;

  return resolvedCurrency;
}

export default useCurrency;
