export const StoreQueryTagEnum = {
  ERROR: "ERROR",
  COUNTRY: "COUNTRY",
  STATE: "STATE",
  CITY: "CITY",
  CURRENCY: "CURRENCY",
  CURRENCY_EXCHANGE: "CURRENCY_EXCHANGE",
  USER: "USER",
  PROJECT: "PROJECT",
  JOB: "JOB",
  PROPOSAL: "PROPOSAL",
  CONTRACT: "CONTRACT",
  INVITE: "INVITE",
  JOB_REFERRAL: "JOB_REFERRAL",
  PROFILE_PORTFOLIO: "PROFILE_PORTFOLIO",
  MILESTONE: "MILESTONE",
  PAYMENT: "PAYMENT",
  PAYMENT_METHOD: "PAYMENT_METHOD",
  PAYMENT_PROVIDER: "PAYMENT_PROVIDER",
  PAYMENT_SCHEDULE: "PAYMENT_SCHEDULE",
  WALLET: "WALLET",
};
